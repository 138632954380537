<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="姓名">
            <el-input
              v-model="tableFilters.name"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="客户名称">
            <BasicSelect
              v-model="tableFilters.clientId"
              :allOptions="_clientOptions"
              keyName="name"
              valueName="id"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" >
          <el-form-item label="QQ">
            <el-input
              v-model="tableFilters.qq"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="微信">
            <el-input
              v-model="tableFilters.wx"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <template v-if="showMoreFilter">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="电话">
              <el-input
                v-model="tableFilters.phoneNo"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="邮箱">
              <el-input
                v-model="tableFilters.email"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="国家">
              <BasicSelect
                v-model="tableFilters.country"
                :allOptions="countryOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="省份/州">
              <BasicSelect
                v-model="tableFilters.province"
                :allOptions="provinceOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="城市">
              <BasicSelect
                v-model="tableFilters.city"
                :allOptions="cityOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="地区">
              <BasicSelect
                v-model="tableFilters.region"
                :allOptions="regionOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="tableFilters.createdAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改日期">
              <el-date-picker
                v-model="tableFilters.lastModifiedAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建人">
              <BasicSelect
                v-model="tableFilters.createdById"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改人">
              <BasicSelect
                v-model="tableFilters.lastModifiedById"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
        </template>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import clientForm from "@/views/client/mixins/clientForm";
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/common";
export default {
  components: {
    BasicSelect,
  },
  mixins: [clientForm],
  data() {
    return {
      showMoreFilter: false,
      clientOptions: [],
      tableFilters: {
        createdAt: [],
        lastModifiedAt: [],
        createdById: null,
        lastModifiedById: null,
        name: "",
        qq: "",
        wx: "",
        phoneNo: "",
        email: "",
        country: "",
        province: "",
        city: "",
        region: "",
        clientId: null,
      },
    };
  },
  computed: {
    userOptions() {
      return this.$store.getters.userOptions;
    },
    regionOptions() {
      return this.$store.getters.regionOptions;
    },
    cityOptions() {
      return this.$store.getters.cityOptions;
    },
    provinceOptions() {
      return this.$store.getters.provinceOptions;
    },
    countryOptions() {
      return this.$store.getters.countryOptions;
    },
     _clientOptions(){
          return this.$store.state.basicOption.clientOptions
        }
  },
  created() {
    this.initFormSelection();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "ClientContactsList") {
          if (Object.keys(this.$route.params).length > 0) {
            for (const key in this.$route.params) {
              if (Object.hasOwnProperty.call(this.tableFilters, key)) {
                this.tableFilters[key] = this.$route.params[key];
              }
            }
            this.showMoreFilter = true;
            this.onSearch();
          }
        }
      },
    },
  },
  methods: {
    onSearch() {
      this.$emit("change", this.tableFilters);
      this.$emit("confirm", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.$emit("change", this.tableFilters);
      this.$emit("clear", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 15px;
  margin-bottom: 0;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>