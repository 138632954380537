<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <keep-alive><FilterSelction @change="onFilterChange" /></keep-alive>
    <basic-table
      hiddenClear
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <!-- <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addRow"
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          >新增</el-button
        > -->
        <!-- <el-button size="small" class="mt-r" @click="multipleFormDialog = true" >批量新增</el-button> -->
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">{{
              scope.row.lastModifiedAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'clientName'">
              <span
                style="padding-left: 5px"
                title="点击查看详情"
                class="text-nav"
                @click="onShowClientDetail(scope.row)"
                >{{ scope.row.clientName }}</span
              >
            </span>

            <span v-else-if="column.prop === 'address.country'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.country
              }}
            </span>
            <span v-else-if="column.prop === 'address.province'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.province
              }}
            </span>
            <span v-else-if="column.prop === 'address.city'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.city }}
            </span>
            <span v-else-if="column.prop === 'address.region'">
              {{ isEmprty(scope.row.address) ? "" : scope.row.address.region }}
            </span>
            <span v-else-if="column.prop === 'address.street'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.street }}
            </span>

            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn && false"
          v-bind="column"
          :key="column.prop"
        >
          <!-- <template slot-scope="scope"> </template> -->
        </el-table-column>
      </template>
      <template #multipleControl> </template>
    </basic-table>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import { GetContactsList } from "./api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import PageHeader from "@/components/PageHeader";
import FilterSelction from "./components/FilterSelction";
import { GetTableOrderParams,ReadLocalColumn } from "@/utils/table";
import config from "@/config";
export default {
  components: {
    BasicTable,
    PageHeader,
    FilterSelction,
  },
  data() {
    return {
      tableLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "name",
      orderDirection: "descending",
      multipleSelected: [],
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 130,
            fixed: "left",
            align: "center",
            sortable: "custom",
          },
          {
            prop: "name",
            label: "姓名",
            showColumn: true,
            fixed: "left",
            minWidth: "130px",
            sortable: "custom",
          },
          {
            prop: "phoneNo",
            label: "电话",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "clientName",
            label: "客户名称",
            showColumn: true,
            fixed: "left",
            minWidth: "130px",
            sortable: "custom",
          },
          {
            prop: "email",
            label: "邮箱",
            showColumn: true,
            minWidth: "150px",
            sortable: "custom",
          },
          {
            prop: "qq",
            label: "QQ",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "wx",
            label: "微信",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "address.country",
            label: "国家",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.province",
            label: "省份",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.city",
            label: "城市",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.region",
            label: "地区",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.street",
            label: "街道",
            showColumn: true,
            minWidth: 100,
            fixed: null,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "最后修改日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "最后修改人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: "120",
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns)
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {
        createdAt: this.tableFilters.createdAt,
        lastModifiedAt: this.tableFilters.lastModifiedAt,
        createdById: this.tableFilters.createdById,
        lastModifiedById: this.tableFilters.lastModifiedById,
        name: this.tableFilters.name,
        qq: this.tableFilters.qq,
        wx: this.tableFilters.wx,
        phoneNo: this.tableFilters.phoneNo,
        email: this.tableFilters.email,
        "address.country": this.tableFilters.country,
        "address.province": this.tableFilters.province,
        "address.city": this.tableFilters.city,
        "address.region": this.tableFilters.region,
        clientId: this.tableFilters.clientId,
      };
    },
    getTableData() {
      this.tableLoading = true;
      GetContactsList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        disabled: null,
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.page = 0;
      this.onRefresh();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onShowClientDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.clientId,
        },
      });
    },

    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>